import { createGlobalStyle } from "styled-components";
import * as rest from "styled-components";
// console.log(rest);
import Color from "color";

const ColorChoices = [
  "#1abc9c",
  "#2ecc71",
  "#3498db",
  "#9b59b6",
  "#34495e",
  "#f1c40f",
  "#e67e22",
  "#e74c3c",
  "#ecf0f1",
  "#95a5a6",
];

const h = new Date().getHours();
const is_dark = h < 5 || h > 19;

const ThemeStyles = {
  colors: {
    primary: Color("#053D9B"),
    secondary: Color("#1515E7"),
    dark: Color("#420a69"),
  },
  is_dark,

  color: Color("#053D9B"),
  darkColor: Color("#34174e"),
  colorSecondary: Color("#1515E7"),
  betterColors: {
    red: Color("#FF0000"),
    yellow: Color("#FFA400"),
    green: Color("#26A65B"),
  },
  blockSize: 500,
  blockHeight: 360,
  expandedHeight: "90vh",
  sidebarSize: 240,
  scrollerWidth: 64,
  mobileStyles: {
    topNavHeight: 32,
  },
  shadows: {
    light: "0 1px 3px rgba(0, 0, 0, 0.13), 0 2px 3px rgba(0, 0, 0, 0.23)",
    heavy: "0 9px 18px rgba(0, 0, 0, 0.19), 0 5px 5px rgba(0, 0, 0, 0.23)",
    newlight: "0px 0px 15px rgba(0, 0, 0, 0.05)",
    newheavy: "0px 0px 15px rgba(0, 0, 0, 0.1)",
    neumorphic:
      "10px 10px 24px 0 rgba(0, 0, 0, 0.15), -5px -5px 20px 0 rgba(245, 245, 245, 0.15)",
    neumorphiclight:
      "2px 2px 8px 0 rgba(0, 0, 0, 0.15), -2px -2px 8px 0 rgba(245, 245, 245, 0.05)",
  },
  linkTextColor: "rgb(50,50,200)",

  primary: "#39395D",
  primaryDark: "#262637",
  accentLightest: "rgba(44, 42, 74, 0.2)",
  accentLight: "#907AD6",
  accent: "rgb(40, 205, 255)",
  accentDark: "rgb(36,192,235)",
  babyBlue: "#4286EC",

  danger: `#FF6161`,

  textPrimary: "rgb(98, 98, 98)",
  textPrimaryDark: "rgb(20, 20, 20)",
  textPrimaryInvert: "rgb(255, 255, 255)",
  textPrimaryInvertDark: "rgb(180, 180, 180)",
  textSecondary: "rgb(140, 140, 140)",

  bgPrimary: "rgb(255, 255, 255)",
  bgPrimaryInvert: "rgb(30, 30, 30)",
  bgPrimaryDark: "rgb(246, 246, 246)",
  bgSecondary: "rgb(252, 252, 252)",

  borderPrimary: "rgb(230, 230, 230)",
  borderPrimaryDark: "rgba(0, 0, 0, 0.16)",
  borderPrimaryInvert: "rgba(255, 255, 255, 0.03)",

  shadowPrimary: "rgba(0, 0, 0, 0.1)",

  hoverPrimary: "rgba(0, 0, 0, 0.06)",
  hoverPrimaryDark: "rgba(0, 0, 0, 0.1)",
  hoverPositive: "rgba(46, 204, 113, .9)",
  hoverNegative: "rgba(231, 76, 60, .9)",
  hoverDarker: "#181820",
  hoverSecondary: "#414052",

  overlay: "rgba(236, 236, 236, 0.88)",
  overlayInvert: "rgba(28, 28, 28, 0.96)",

  textRegular: "400",
  textBold: "500",
  textBlack: "700",
  textSM: "10px",
  textMD: "13px",
  textLG: "16px",
  textXL: "18px",
  textXXL: "20px",

  buttonBorderRadius: `4px`,
  borderRadius: "15px",

  inputBackgroundDark: "rgba(24, 24, 32, 0.61);",
};

const GlobalStyle = createGlobalStyle`

@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700');

  * {
		-webkit-overflow-scrolling: touch;
    font-family: 'Montserrat', sans-serif;
  }

  html {
    overflow: hidden;
    height: 100%;
}

  body {
      height: 100%;
      overflow: auto;
  }

  html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var, b, u, i, center,
  dl, dt, dd, ol, ul, li, fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td {
      margin: 0; padding: 0;  border: 0; outline: 0; font-size: 100%;
      vertical-align: baseline; background: transparent; box-sizing: border-box;
  }

	// for safari
	input[type="submit"] {
		 -webkit-appearance: none;
	}

  body { line-height: 1; }
  ol, ul { list-style: none; }
  blockquote, q { quotes: none; }
  blockquote:before, blockquote:after, q:before, q:after { content: ''; }
  :focus { outline: 0; }
  ins { text-decoration: none; }
  del { text-decoration: line-through; }
  table { border-collapse: collapse; border-spacing: 0; }

  html, body, #root {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    margin: 0;
  }

  body {
    color: #363B45;
    font-size: 14px;
    font-weight: 300;
  }

  .fade-enter {
    opacity: 0;

  }
  .fade-enter.fade-enter-active {
    opacity: 1;
    position: absolute;
    left:0;
    bottom:0 ;
    right: 0;
    transition: opacity .3s ease-out;
  }

  .fade-leave {
    opacity: 1;

  }
  .fade-leave.fade-leave-active {
    position: absolute;
    left:0;
    bottom:0 ;
    right: 0;
    transition: opacity .3s ease-out;
    opacity: 0;
  }

  .icon-spin {
            animation: icon-spin 2s infinite linear;
  }

  @keyframes icon-spin {
    0% {
              transform: rotate(0deg);
    }
    100% {
              transform: rotate(359deg);
    }
  }

  #lightboxBackdrop {
    z-index: 10025;
  }

  .MuiTooltip-tooltip.custom-calendar-tooltip {
    background-color: #ffffff;
    color: rgba(0, 0, 0, 0.87);
    max-width: 400px;
    border: 1px solid #dadde9;
    font-weight: 400;
    font-size: 12px;
    padding: 20px;
    margin-top: 0px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
    border-radius: 15px;

    &.no-left {
      margin-left: 0;
    }
  }

  .MuiPopover-paper.custom-calendar-popover {
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
    min-width: 200px;
    max-width: 200px;

    .event-date {
      font-weight: 500;
      margin-bottom: 10px;
      text-align: center;
    }

    .miniEvent {
      font-size: 12px;
      padding: 8px;
      display: flex;
      align-items: center;

      .colored-div {
        width: 25px;
        height: 25px;
        border-radius: 2px;
        margin-right: 5px;

        &.mini {
          width: 15px;
          height: 15px;
        }

        &.teal {
          background-color: #18B0CF;
        }

        &.purple {
          background-color: #7986CB;
        }

        &.orange {
          background-color: #FF9900;
        }

        &.busy {
          background-color: #007AD8;
        }

        &.private {
          background-color: #007AD8;
        }
        &.timeauthor {
          background-color: #18B0CF;
        }

        &.google {
          background-color: #7986CB;
        }
        &.apple {
          background-color: #000000;
        }
        &.exchange {
          background-color: #039BE5;
        }
        &.live_connect {
          background-color: #E67C73;
        }
        &.office {
          background-color: #D83B01;
        }

        &.holiday {
          background-color: #E01E5A;
        }

        &.free {
          background-color: #FF9900;
        }
        &.red {
          background-color: #D50000;
        }
        &.pink {
          background-color: #E67C73;
        }
        &.yellow {
          background-color: #F6BF26;
        }
        &.lightgreen {
          background-color: #33B679;
        }
        &.green {
          background-color: #0B8043;
        }
        &.lightblue {
          background-color: #039BE5;
        }
        &.blue {
          background-color: #3F51B5;
        }
        &.violet {
          background-color: #8E24AA;
        }
        &.grey {
          background-color: #363B45;
        }
        &.black {
          background-color: #000000;
        }

        + div {

        }
      }

      &.active {
        background-color: rgba(255, 153, 0, 0.5);
        border-radius: 5px;
      }

      svg {
        display: none;
      }

      div.datetime {
        width: 55px;
      }

      div.topic {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 13px;
      }
    }

  }

  .custom-tooltip-calendar  {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);

    &::after {
      display: none;
    }

    &.top-right {
      border-radius: 15px 0 15px 15px;
    }

    &.top-left {
      border-radius: 0 15px 15px 15px;
    }

    &.bottom-left {
      border-radius: 15px 15px 15px 0;
    }

    &.bordered-tooltip {
      border: 1px solid rgba(54, 59, 69, 0.5);
    }

    &.container.main {
      background-color: #FFFFFF !important; 
      color: #363B45 !important;
    }
  }

	.MultiColorIcon {
		clip-rule: evenodd;
		fill-rule: evenodd;
		height: 24px;
		width: 24px;
	}

	.MultiColorIcon-path--white {
		fill: #fff;
	}

	.MultiColorIcon-path--fadedBlack {
		fill: rgba(21,27,38,.75);
	}

  .rdw-link-modal-target-option {
    display: none;
  }

  .rdw-link-modal {
    height: 190px;
  }

	*::-webkit-scrollbar {
		width: 8px;
		height: 8px;
	}

	*::-webkit-scrollbar-thumb {
		background-color: #aaa;
		border-radius: 24px;
	}

	*::-webkit-scrollbar-track {
		border-radius: 24px;
	}

  .mobile {
    display: none;
  }

  .mobile.imp {
    display: none !important;
  }


	.rctooltipL {
    .rc-tooltip-inner {
      background: #FFFFFF;
      color: #363B45;
    }

    .border-light {
      border-color: #E9ECEF;
    }

    .switch input:checked + .slider:before {
      background-color: #FF8200;
    } 
		
    .switch input:checked + .slider {
      box-shadow: 0 0 0 2px #FF8200, 0 0 8px #FF8200;
    }
	}

	.rctooltipD {
    .rc-tooltip-inner {
      background: #181820;
      color: white;
    }

		.border-light {
      border-color: #414052;
    } 

		.switch .slider {
      box-shadow: 0 0 0 2px #414052, 0 0 4px #414052;
    }

		.switch input:checked + .slider:before {
      background-color: #008BFF;
    } 
		.switch input:checked + .slider {
      box-shadow: 0 0 0 2px #008BFF, 0 0 8px #008BFF;
    } 
		
	}

  .workspace-dropdown, .archive-dropdown {
    transform: translate(0, -25px);
    opacity: 1;
    z-index: 102;

    .rc-tooltip-arrow {
      display: none;
    }

    &.rc-tooltip-placement-bottom, 
    &.rc-tooltip-placement-bottomLeft, 
    &.rc-tooltip-placement-bottomRight {
      padding-top: 0px;
    }

  }

  .profile-dropdown {
    width: 320px;
    transform: translate(0, -20px);
  }


  .archive-dropdown {
    z-index: 1000;
    transform: translate(-10px, 13px);
  }

  #link-list, #color-list, #end-time-duration {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
    z-index: 202;
    background: white;
    border-radius: 5px;
  }

  .custom-scroll-thumb {
    cursor: pointer;
    border-radius: inherit;
  }

  .lightnextbtn, .darknextbtn, .lightprevbtn, .darkprevbtn {
    border: none;
    outline: none;
    padding: 7px 30px;
    border-radius: 30px;
    
    &:not(:disabled) {
      cursor: pointer;
    }

    &:disabled {
      opacity: 0.5;
    }
  }
  
  .lightnextbtn {
    background: linear-gradient(90deg, #FF9900 0%, #FF8200 100%); 
    color: white;
  }

  .darknextbtn {
		background: #008BFF;
    color: white;
  }

  .lightprevbtn {
    background: none; 
    color: #363B45;
  }

  .darkprevbtn {
		background: none;
    color: white;
  }

  @media (max-width: 555px) {
    .mobile {
      display: block;
    }

    .mobile.imp {
      display: block !important;
    }

    .mobile.flex {
      display: flex;
    }

    .mobile.flex.imp {
      display: flex !important;
    }

    .desktop {
      display: none;
    }
  }
`;

export { ColorChoices, GlobalStyle };
export default ThemeStyles;
